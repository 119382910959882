import React, { Component } from "react";

const handleCompensate = 1;

export default class PuzzlePiece extends Component {
    constructor() {
        super();

        this.box = null;

        this.state = {
            isActive: false,
            angle: 0,
            startAngle: 0,
            currentAngle: 0,
            boxCenterPoint: {}
        };

        this.getPositionFromCenter = this.getPositionFromCenter.bind(this);
        this.mouseDownHandler = this.mouseDownHandler.bind(this);
        this.mouseUpHandler = this.mouseUpHandler.bind(this);
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.deslectAll = this.deslectAll.bind(this);
    }

    // to avoid unwanted behaviour, deselect all text
    deslectAll() {
        if (document.selection) {
            document.selection.empty();
        } else if (window.getSelection) {
            window.getSelection().removeAllRanges();
        }
    }

    // method to get the positionof the pointer event relative to the center of the box
    getPositionFromCenter(e) {
        // const minCenterTolerance = 10;
        const { boxCenterPoint } = this.state;
        const fromBoxCenter = {
            x: e.clientX - boxCenterPoint.x,
            y: -(e.clientY - boxCenterPoint.y)
        };

        // if (fromBoxCenter.x < 0 && fromBoxCenter.x > -minCenterTolerance) fromBoxCenter.x = -minCenterTolerance;
        // if (fromBoxCenter.x > 0 && fromBoxCenter.x < minCenterTolerance) fromBoxCenter.x = minCenterTolerance;
        // if (fromBoxCenter.y < 0 && fromBoxCenter.y > -minCenterTolerance) fromBoxCenter.y = -minCenterTolerance;
        // if (fromBoxCenter.y > 0 && fromBoxCenter.y < minCenterTolerance) fromBoxCenter.y = minCenterTolerance;

        return fromBoxCenter;
    }

    mouseDownHandler(e) {
        e.stopPropagation();
        const fromBoxCenter = this.getPositionFromCenter(e);
        const newStartAngle = 90 - Math.atan2(fromBoxCenter.y, fromBoxCenter.x) * (180 / Math.PI);
        this.setState({ startAngle: newStartAngle, isActive: true });
    }

    mouseUpHandler(e) {
        this.deslectAll();
        // e.stopPropagation();
        const { isActive, angle, startAngle, currentAngle } = this.state;
        if (isActive) {
            const newCurrentAngle = currentAngle + (angle - startAngle);
            this.setState({ isActive: false, currentAngle: newCurrentAngle });
            this.props.onDegChange(newCurrentAngle);
        }
    }

    mouseMoveHandler(e) {
        const { isActive, currentAngle, startAngle } = this.state;
        if (isActive) {
            const fromBoxCenter = this.getPositionFromCenter(e);
            const newAngle = 90 - Math.atan2(fromBoxCenter.y * handleCompensate, fromBoxCenter.x * handleCompensate) * (180 / Math.PI);

            this.box.style.transform = "rotate(" + (currentAngle + (newAngle - (startAngle ? startAngle : 0))) + "deg)";
            this.setState({ angle: newAngle });
        } // active conditional
    }

    componentDidMount() {
        const boxPosition = this.box.getBoundingClientRect();
        // get the current center point
        const boxCenterX = boxPosition.left + boxPosition.width / 2;
        const boxCenterY = boxPosition.top + boxPosition.height / 2;

        // update the state
        this.setState({ boxCenterPoint: { x: boxCenterX, y: boxCenterY } });

        // in case the event ends outside the box
        // window.onmouseup = this.mouseUpHandler;
        window.addEventListener("mouseup", (event) => { this.mouseUpHandler(event) });

        // window.onmousemove = this.mouseMoveHandler;
        window.addEventListener("mousemove", (event) => { this.mouseMoveHandler(event) });
    }

    convertDegToSend(deg) {
        return deg;
    }

    render() {
        return (
            <div
                src={this.props.image}
                className={"opacity-90 hover:opacity-100 w-[100px] h-[100px] absolute hover:z-10 transition-opacity " + (this.props.className || '') + ' ' + (this.state.isActive ? 'cursor-grabbing' : 'cursor-grab')}
                draggable={false}
                onMouseDown={this.mouseDownHandler}
                onMouseUp={this.mouseUpHandler}
                ref={div => (this.box = div)}>
                <div className='relative w-full h-full'>

                </div>

                <div className={'bg-cover bg-no-repeat absolute drop-shadow-xl ' + (this.props.imageClassName)} style={{ backgroundImage: 'url(' + this.props.image + ')' }}></div>

            </div>
        );
    }
}