import { useState, useEffect } from 'react';
import axios from 'axios';

import { MdOutlineRefresh, MdCheck } from 'react-icons/md';

import PuzzlePiece from './PuzzlePiece.js'

export default function App() {
  const [deg1, setDeg1] = useState(0);
  const [deg2, setDeg2] = useState(0);
  const [deg3, setDeg3] = useState(0);
  const [deg4, setDeg4] = useState(0);

  const [feedback, setFeedback] = useState(false);

  // useEffect(() => {
  //   const url = '/service/verifyanswer/?turingtestid=' + document.turingTestId + '&answer=' + deg1 + ',' + deg2 + ',' + deg3 + ',' + deg4;
  //   const body = {}

  //   axios.post(url, body).then(function (response) {
  //     if (response.data && response.data.d && response.data.d.is_valid)
  //       setFeedback(true);
  //   }).catch(function (error) {
  //     console.log(error);
  //   });
  // }, [deg1, deg2, deg3, deg4]);

  const send = () => {
    const url = '/service/verifyanswer/?turingtestid=' + document.turingTestId + '&answer=' + deg1 + ',' + deg2 + ',' + deg3 + ',' + deg4;
    const body = {}

    axios.post(url, body).then(function (response) {
      if (response.data && response.data.d && response.data.d.is_valid)
        setFeedback(true);
    }).catch(function (error) {
      console.log(error);
    });
  };

  const convertDegToBase = (deg) => {
    return deg % 360;
  };

  return (
    <div className='relative'>

      <button className='absolute -top-4 -right-4 flex items-center justify-center bg-neutral-50/75 shadow-xl rounded-full size-10 group hover:bg-neutral-50/100 transition-colors'>
        <MdOutlineRefresh className='text-xl opacity-50 group-hover:opacity-100 transition-opacity' />
      </button>

      {feedback && <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white/50 backdrop-blur-sm rounded-[30px] z-20'>

        <div className='size-16 rounded-full bg-white shadow-xl flex items-center justify-center'>
          <MdCheck className='text-5xl text-green-700' />
        </div>

      </div>}

      <div className='w-[216px] flex flex-col rounded-[30px] shadow-2xl bg-white p-2 z-10'>

        <div className='w-[202px] h-[202px] bg-neutral-50 border shadow-inner drop-shadow-xl rounded-3xl z-10'>

          <div className='absolute top-0 left-0 w-full h-full'>

            <PuzzlePiece className='top-0 right-0' imageClassName='w-[128px] h-[128px] top-0 right-0' image={document.image1} onDegChange={(deg) => setDeg1(convertDegToBase(deg))} />
            <PuzzlePiece className='bottom-0 right-0' imageClassName='w-[100px] h-[100px] bottom-0 right-0' image={document.image2} onDegChange={(deg) => setDeg2(convertDegToBase(deg))} />
            <PuzzlePiece className='bottom-0 left-0' imageClassName='w-[127px] h-[127px] bottom-0 left-0 bg-bottom-left' image={document.image3} onDegChange={(deg) => setDeg3(convertDegToBase(deg))} />
            <PuzzlePiece className='top-0 left-0' imageClassName='w-[100px] h-[100px] top-0 right-0 bg-top-right' image={document.image4} onDegChange={(deg) => setDeg4(convertDegToBase(deg))} />

          </div>

        </div>

        <div className='w-full rounded-b-3xl bg-white border-t border-x pt-4 -mt-4'>

          <div className='text-xs text-neutral-700 font-medium text-center select-none py-2'>Rotate the puzzle pieces<br />to the correct position</div>

          <button onClick={() => send()} className='w-full h-10 rounded-3xl flex items-center justify-center font-bold text-xs tracking-wide opacity-75 hover:opacity-100 hover:bg-neutral-100 transition border'>
            I'm finished
          </button>

        </div>



      </div>

    </div >
  );
}